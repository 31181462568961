<template>
  <div>
    <div class="statistics-box">
      <div class="statistics-tab">
        <x-area-select :areaData="areaData" @change="areaChange"></x-area-select>
        <x-time-select :bindYear="selectObject.year" :bindMonth="selectObject.month" @change="timeChange"></x-time-select>
      </div>
      <ul class="statistics-list">
        <li>
          <span>{{performanceCommonVos.bilanCount||0}}</span>
          <i>必兰数量(盒)</i>
        </li>
        <li>
          <span>{{performanceCommonVos.bilanTarget||0}}</span>
          <i>目标数量(盒)</i>
        </li>
        <li>
          <span>{{performanceCommonVos.bilanCompletion||0}}%</span>
          <i>完成度</i>
        </li>
      </ul>
      <ul class="statistics-list">
        <li>
          <span>{{performanceCommonVos.pailiaoCount||0}}</span>
          <i>派力奥数量(支)</i>
        </li>
        <li>
          <span>{{performanceCommonVos.pailiaoTarget||0}}</span>
          <i>目标数量(支)</i>
        </li>
        <li>
          <span>{{performanceCommonVos.pailiaoCompletion||0}}%</span>
          <i>完成度</i>
        </li>
      </ul>
    </div>
    <div class="report-box">
      <div class="report-tab-box" :class="{'report-tab-right':tabIndex}">
        <span @click="tabIndex=0;">月</span>
        <span @click="tabIndex=1;">季度</span>
      </div>
      <div class="report-title">
        <span>年度完成情况</span>
      </div>
      <ul class="dot-list">
        <li>目标金额</li>
        <li>必兰</li>
        <li>派力奥</li>
      </ul>
      <div ref="drugsEchartsCanvas" class="canvas-box"></div>
    </div>
  </div>
</template>

<script>
import areaSelect from "@/components/areaSelect";
import timeSelect from "@/components/timeSelect";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import { getMedicinePerformance } from "@/api/reportCenter";

export default {
  name: "x-drugs-index",
  data () {
    return {
      tabIndex: 0,
      drugsEchartsObj: null,
      selectObject: {
        city: "",
        province: "",
        regionValue: "",
        saleId: "",
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      performanceCommonVos: {},
      xhrData: {},
      echartsMonthOption: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,15,36,0.90)",
          borderWidth: 0,
          axisPointer: {
            type: "shadow"
          },
          textStyle: {
            color: "#ffffff",
            fontSize: 12
          },
          formatter (params) {
            let q;
            switch (params[0].dataIndex) {
              case 0:
              case 1:
              case 2:
                q = "Q1";
                break;
              case 3:
              case 4:
              case 5:
                q = "Q2";
                break;
              case 6:
              case 7:
              case 8:
                q = "Q3";
                break;
              case 9:
              case 10:
              case 11:
                q = "Q4";
                break;
              default:
                break;
            }
            const formatterStr = `<div>2020年${q}</div>
                                          <div class="tool-tip-item">
                                            ${params[4].marker}
                                            <span>必兰目标金额：${params[4].value}<br/>
                                            派力奥目标金额：${params[5].value}</span>
                                          </div>
                                          <div class="tool-tip-item">
                                            ${params[0].marker}
                                            <span>必兰：${params[0].value}盒<br/>
                                            完成度：${params[2].value}%</span>
                                          </div>
                                          <div class="tool-tip-item">
                                            ${params[1].marker}
                                            <span>派力奥：${params[1].value}盒<br/>
                                            完成度：${params[3].value}%</span>
                                          </div>`;
            return formatterStr;
          }
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "5%",
          bottom: "2%",
          containLabel: true
        },
        xAxis: [{
          name: "(月)",
          nameLocation: "start",
          nameTextStyle: {
            color: "#8D8E93",
            padding: [7, 0, 0, 0],
            align: "left",
            verticalAlign: "top"
          },
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#E6EAEF"
            }
          },
          axisLabel: {
            color: "#8D8E93"
          },
          axisTick: {
            show: false
          },
          axisPointer: {
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.25)"
            }
          },
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
        }, {
          show: false,
          type: "category",
          data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
        }],
        yAxis: [{
          type: "value",
          name: "(元)",
          nameLocation: "end",
          nameTextStyle: {
            color: "#8D8E93",
            padding: [0, 3, 0, 0],
            align: "right",
            verticalAlign: "bottom"
          },
          splitNumber: 4,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E6EAEF"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          },
          axisLabel: {
            color: "#8D8E93"
          }
        }, {
          type: "value",
          show: false
        }],
        series: [{
          name: "必兰目标金额",
          type: "bar",
          barWidth: 6,
          itemStyle: {
            barBorderRadius: [2, 2, 0, 0],
            color: "#F7932B"
          },
          xAxisIndex: 1,
          data: []
        }, {
          name: "派力奥目标金额",
          type: "bar",
          barWidth: 6,
          itemStyle: {
            barBorderRadius: [2, 2, 0, 0],
            color: "#F7932B"
          },
          xAxisIndex: 1,
          data: []
        }, {
          name: "必兰",
          type: "bar",
          barWidth: 6,
          itemStyle: {
            barBorderRadius: [2, 2, 0, 0],
            color: "#2B82F7"
          },
          data: []
        }, {
          name: "派力奥",
          type: "bar",
          barWidth: 6,
          itemStyle: {
            barBorderRadius: [2, 2, 0, 0],
            color: "#0FD5C5"
          },
          data: []
        }, {
          name: "必兰完成度",
          type: "line",
          itemStyle: {
            opacity: 0
          },
          lineStyle: {
            opacity: 0
          },
          yAxisIndex: 1,
          data: []
        }, {
          name: "派力奥完成度",
          type: "line",
          itemStyle: {
            opacity: 0
          },
          lineStyle: {
            opacity: 0
          },
          yAxisIndex: 1,
          data: []
        }]
      },
      echartsQuarterOption: {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,15,36,0.90)",
          borderWidth: 0,
          axisPointer: {
            type: "shadow"
          },
          textStyle: {
            color: "#ffffff",
            fontSize: 12
          },
          formatter (params) {
            const formatterStr = `<div>2020年${params[0].axisValue}</div>
                                          <div class="tool-tip-item">
                                            ${params[4].marker}
                                            <span>必兰目标金额：${params[4].value}<br/>
                                            派力奥目标金额：${params[5].value}</span>
                                          </div>
                                          <div class="tool-tip-item">
                                            ${params[0].marker}
                                            <span>必兰：${params[0].value}盒<br/>
                                            完成度：${params[2].value}%</span>
                                          </div>
                                          <div class="tool-tip-item">
                                            ${params[1].marker}
                                            <span>派力奥：${params[1].value}盒<br/>
                                            完成度：${params[3].value}%</span>
                                          </div>`;
            return formatterStr;
          }
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "5%",
          bottom: "2%",
          containLabel: true
        },
        xAxis: [{
          name: "(季度)",
          nameLocation: "start",
          nameTextStyle: {
            color: "#8D8E93",
            padding: [7, 0, 0, 0],
            align: "center",
            verticalAlign: "top"
          },
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#E6EAEF"
            }
          },
          axisLabel: {
            color: "#8D8E93"
          },
          axisTick: {
            show: false
          },
          axisPointer: {
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.25)"
            }
          },
          data: ["Q1", "Q2", "Q3", "Q4"]
        }, {
          show: false,
          type: "category",
          data: ["Q1", "Q2", "Q3", "Q4"]
        }],
        yAxis: [{
          type: "value",
          name: "(元)",
          nameLocation: "end",
          nameTextStyle: {
            color: "#8D8E93",
            padding: [0, 3, 0, 0],
            align: "right",
            verticalAlign: "bottom"
          },
          splitNumber: 4,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E6EAEF"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed"
            }
          },
          axisLabel: {
            color: "#8D8E93"
          }
        }, {
          type: "value",
          show: false
        }],
        series: [{
          name: "必兰目标金额",
          type: "bar",
          barWidth: 20,
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0],
            color: "#F7932B"
          },
          xAxisIndex: 1,
          data: []
        }, {
          name: "派力奥目标金额",
          type: "bar",
          barWidth: 20,
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0],
            color: "#F7932B"
          },
          xAxisIndex: 1,
          data: []
        }, {
          name: "必兰",
          type: "bar",
          barWidth: 20,
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0],
            color: "#2B82F7"
          },
          data: []
        }, {
          name: "派力奥",
          type: "bar",
          barWidth: 20,
          itemStyle: {
            barBorderRadius: [5, 5, 0, 0],
            color: "#0FD5C5"
          },
          data: []
        }, {
          name: "必兰完成度",
          type: "line",
          itemStyle: {
            opacity: 0
          },
          lineStyle: {
            opacity: 0
          },
          yAxisIndex: 1,
          data: []
        }, {
          name: "派力奥完成度",
          type: "line",
          itemStyle: {
            opacity: 0
          },
          lineStyle: {
            opacity: 0
          },
          yAxisIndex: 1,
          data: []
        }]
      }
    };
  },
  props: {
    areaData: {
      type: Array,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  created () { },
  methods: {
    getData () {
      getMedicinePerformance(this.selectObject).then(xhr => {
        this.xhrData = xhr.data;
        this.performanceCommonVos = {};
        if (this.xhrData.performanceCommonVos.length > 0) {
          if (this.selectObject.month) {
            this.xhrData.performanceCommonVos.map(item => {
              if (item.month == this.selectObject.month) {
                this.performanceCommonVos = item;
              }
            });
          } else {
            this.performanceCommonVos = this.xhrData.totalCommonVo || {};
          }
        }
        this.setEchartsData(this.tabIndex);
      });
    },
    setEchartsData (index) {
      this.drugsEchartsObj.clear();
      switch (index) {
        case 0:
          this.echartsMonthOption.series[0].data = this.xhrData.performanceECharVos[0].data;
          this.echartsMonthOption.series[1].data = this.xhrData.performanceECharVos[3].data;
          this.echartsMonthOption.series[2].data = this.xhrData.performanceECharVos[1].data;
          this.echartsMonthOption.series[3].data = this.xhrData.performanceECharVos[4].data;
          this.echartsMonthOption.series[4].data = this.xhrData.performanceECharVos[2].data;
          this.echartsMonthOption.series[5].data = this.xhrData.performanceECharVos[5].data;
          this.drugsEchartsObj.setOption(this.echartsMonthOption);
          break;
        case 1:
          this.echartsQuarterOption.series[0].data = this.xhrData.seasonPerformanceEChar[0].data;
          this.echartsQuarterOption.series[1].data = this.xhrData.seasonPerformanceEChar[3].data;
          this.echartsQuarterOption.series[2].data = this.xhrData.seasonPerformanceEChar[1].data;
          this.echartsQuarterOption.series[3].data = this.xhrData.seasonPerformanceEChar[4].data;
          this.echartsQuarterOption.series[4].data = this.xhrData.seasonPerformanceEChar[2].data;
          this.echartsQuarterOption.series[5].data = this.xhrData.seasonPerformanceEChar[5].data;
          this.drugsEchartsObj.setOption(this.echartsQuarterOption);
          break;
        default:
          break;
      }
    },
    areaChange (item) {
      this.selectObject.regionValue = item[0].code;
      this.selectObject.province = item[1].code;
      this.selectObject.city = item[2].code;
      this.selectObject.saleId = item[3].code;
      this.getData();
    },
    timeChange (data) {
      this.selectObject.year = data.year.code;
      this.selectObject.month = data.month.code || null;
      this.getData();
    }
  },
  watch: {
    areaData (newVal) {
      this.selectObject.regionValue = newVal[0].regionValue;
      if (this.show && !this.drugsEchartsObj) {
        this.$nextTick(() => {
          this.drugsEchartsObj = this.$echarts.init(this.$refs.drugsEchartsCanvas);
          this.drugsEchartsObj.setOption(this.echartsMonthOption);
          this.getData();
        });
      }
    },
    tabIndex (newVal) {
      this.setEchartsData(newVal);
    },
    show (newVal) {
      if (newVal && !this.drugsEchartsObj) {
        this.$nextTick(() => {
          this.drugsEchartsObj = this.$echarts.init(this.$refs.drugsEchartsCanvas);
          this.drugsEchartsObj.setOption(this.echartsMonthOption);
          this.getData();
        });
      }
    }
  },
  components: {
    [areaSelect.name]: areaSelect,
    [timeSelect.name]: timeSelect
  }
};
</script>

<style scoped>
@import '../../../../assets/style/achievements.css';
</style>
