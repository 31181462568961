<template>
  <span>
    <i v-if="checkYearItem" @click="popupShow=true;">{{checkYearItem.name}}{{checkMonthItem.name}}</i>
    <mt-popup v-model="popupShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupShow=false;">取消</button>
          <button @click="finishDateEvent">完成</button>
        </div>
        <mt-picker :slots="slotsDate" value-key="name" @change="onValuesDateChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </span>
</template>

<script>
import { Popup, Picker } from "mint-ui";

export default {
  name: "x-time-select",
  data () {
    return {
      popupShow: false,
      regionInit: false,
      checkYearItem: null,
      checkYearItemTemp: null,
      checkMonthItem: null,
      checkMonthItemTemp: null,
      slotsDate: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }, {
        flex: 1,
        values: [{
          code: null,
          name: null
        }],
        textAlign: "center"
      }]
    };
  },
  props: {
    bindYear: {
      type: [Number, String],
      default: null
    },
    bindMonth: {
      type: [Number, String],
      default: null
    }
  },
  created () {
    setTimeout(() => {
      this.getYear();
      this.setData();
    }, 0);
  },
  methods: {
    getYear () {
      const nowTime = new Date(),
        nowYear = nowTime.getFullYear();
      for (let i = 2020; i <= nowYear; i++) {
        this.slotsDate[0].values.push({
          code: i,
          name: i + "年"
        });
      }
    },
    getMonth (year) {
      const nowTime = new Date(),
        nowYear = nowTime.getFullYear(),
        nowMonth = nowTime.getMonth() + 1,
        arr = [{
          code: 0,
          name: "全部"
        }];
      const jMin = year == 2020 ? 11 : 1;
      const jMax = year == nowYear ? nowMonth : 12;
      for (let j = jMax; j >= jMin; j--) {
        arr.push({
          code: j,
          name: j + "月"
        });
      }
      return arr;
    },
    onValuesDateChange (picker, values) {
      if (this.regionInit) {
        this.checkYearItemTemp = values[0];
        this.checkMonthItemTemp = values[1];
        picker.setSlotValues(1, this.getMonth(values[0].code));
      } else {
        this.regionInit = true;
      }
    },
    finishDateEvent () {
      this.popupShow = false;
      this.checkYearItem = this.checkYearItemTemp;
      this.checkMonthItem = this.checkMonthItemTemp;
      this.$emit("change", {
        "year": this.checkYearItem,
        "month": this.checkMonthItem
      });
    },
    setData () {
      this.slotsDate[0].values.map((item, index) => {
        if (this.bindYear == item.code) {
          this.checkYearItemTemp = [item];
          this.checkYearItem = item;
          this.slotsDate[0].defaultIndex = index;
        }
      });
      this.slotsDate[1].values = this.getMonth(this.bindYear);
      this.slotsDate[1].values.map((item, index) => {
        if (this.bindMonth == item.code) {
          this.checkMonthItemTemp = [item];
          this.checkMonthItem = item;
          this.slotsDate[1].defaultIndex = index;
        }
      });
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
</style>
