<template>
  <span>
    <i @click="popupShow=true;">{{title}}</i>
    <mt-popup v-model="popupShow" position="bottom">
      <div class="popup-box">
        <div class="popup-header" @touchmove.prevent>
          <button @click="popupShow=false;">取消</button>
          <button @click="finishEvent">完成</button>
        </div>
        <mt-picker :slots="slotsData" value-key="name" @change="onValuesChange" @touchmove.native.stop.prevent></mt-picker>
      </div>
    </mt-popup>
  </span>
</template>

<script>
import { Popup, Picker } from "mint-ui";

export default {
  name: "x-area-select",
  data () {
    return {
      title: null,
      checkItemTemp: null,
      checkItem: null,
      isAjaxEnd: false,
      slotsData: [{
        flex: 1,
        values: [],
        textAlign: "center"
      }, {
        flex: 1,
        values: [{
          name: "全部",
          code: null
        }],
        textAlign: "center"
      }, {
        flex: 1,
        values: [{
          name: "全部",
          code: null
        }],
        textAlign: "center"
      }, {
        flex: 1,
        values: [{
          name: "全部",
          code: null
        }],
        textAlign: "center"
      }],
      popupShow: false
    };
  },
  props: {
    areaData: {
      type: Array,
      default: () => []
    }
  },
  created () { },
  methods: {
    setAreaData () {
      this.areaData.map(item => {
        this.slotsData[0].values.push({
          name: item.region,
          code: item.regionValue
        });
      });
      this.areaData[0].provinceList.map(item => {
        this.slotsData[1].values.push({
          name: item.provinceName,
          code: item.provinceName
        });
      });
      // this.areaData[0].provinceList[0].cityList.map(item => {
      //   this.slotsData[2].values.push({
      //     name: item.cityName,
      //     code: item.cityName
      //   });
      // });
      // this.areaData[0].provinceList[0].cityList[0].saleList.map(item => {
      //   this.slotsData[3].values.push({
      //     name: item.name,
      //     code: item.id
      //   });
      // });
      this.title = this.slotsData[0].values[0].name;
      setTimeout(() => {
        this.isAjaxEnd = true;
      }, 0);
    },
    finishEvent () {
      this.popupShow = false;
      this.checkItem = this.checkItemTemp;
      this.title = this.checkItem[this.checkItem.length - 1].name;
      for (let i = 0; i < this.checkItem.length; i++) {
        if (!this.checkItem[i].code) {
          this.title = this.checkItem[i - 1].name;
          break;
        }
      }
      this.$emit("change", this.checkItem);
    },
    onValuesChange (picker, values) {
      if (this.isAjaxEnd) {
        const arr1 = [{ name: "全部", code: null }],
          arr2 = [{ name: "全部", code: null }],
          arr3 = [{ name: "全部", code: null }];
        this.areaData.map(i => {
          if (i.regionValue == values[0].code) {
            i.provinceList.map(j => {
              arr1.push({
                name: j.provinceName,
                code: j.provinceName
              });
              if (j.provinceName == values[1].code) {
                j.cityList.map(k => {
                  arr2.push({
                    name: k.cityName,
                    code: k.cityName
                  });
                  if (k.cityName == values[2].code) {
                    k.saleList.map(l => {
                      arr3.push({
                        name: l.name,
                        code: l.id
                      });
                    });
                  }
                });
              }
            });
          }
        });
        picker.setSlotValues(1, arr1);
        picker.setSlotValues(2, arr2);
        picker.setSlotValues(3, arr3);
      }
      this.checkItemTemp = values;
    }
  },
  watch: {
    areaData () {
      this.setAreaData();
    }
  },
  components: {
    [Popup.name]: Popup,
    [Picker.name]: Picker
  }
};
</script>

<style scoped>
</style>
