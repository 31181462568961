<template>
  <div class="app-box">
    <x-tab :tabList="tabList"></x-tab>
    <x-scroll-view :top="0.52" class="bg-color">
      <x-drugs-index v-show="tabList[0].check" :show="tabList[0].check" :areaData="xhrData"></x-drugs-index>
      <x-apparatus-index v-show="tabList[1].check" :show="tabList[1].check" :areaData="xhrData"></x-apparatus-index>
    </x-scroll-view>
  </div>
</template>

<script>
import tab from "@/components/tab";
import scrollView from "@/components/scrollView";
import drugsIndex from "@/page/reportCenter/achievements/drugs/index";
import apparatusIndex from "@/page/reportCenter/achievements/apparatus/index";
import { getFilterList } from "@/api/reportCenter";

export default {
  data () {
    return {
      xhrData: [],
      tabList: [{
        id: 1,
        text: "药品",
        check: true
      }, {
        id: 2,
        text: "器械",
        check: false
      }]
    };
  },
  created () {
    document.title = "绩效信息";
    this.getData();
  },
  methods: {
    getData () {
      getFilterList().then(xhr => {
        this.xhrData = xhr.data;
      });
    }
  },
  watch: {
    $route (to, from) {
      if (to.meta.index <= from.meta.index) {
        document.title = "绩效信息";
      }
    }
  },
  components: {
    [tab.name]: tab,
    [scrollView.name]: scrollView,
    [drugsIndex.name]: drugsIndex,
    [apparatusIndex.name]: apparatusIndex
  }
};
</script>

<style scoped>
</style>
